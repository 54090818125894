<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-4"></div>
                            <div class="col-sm-8">
                                <div class="text-sm-right">
                                    <slot name="buttons"></slot>
                                    <!--<button
                                        type="button"
                                        class="btn btn-success btn-rounded mb-2 mr-2"
                                        @click="addStockist"
                                    >
                                        <i class="mdi mdi-plus mr-1"></i> Add
                                        Stockist
                                    </button>-->
                                </div>
                            </div>
                            <!-- end col-->
                        </div>
                        <div>
                            <div v-show="items_count == 0">
                                <div
                                    style="text-align: center; font-size: 25px"
                                >
                                    <span>Please add an item!</span>
                                </div>
                            </div>
                            <div v-show="items_count > 0">
                                <slot name="content"></slot>
                                <!-- the table slot: table will be renderd in here-->
                                <slot name="table"></slot>
                                <!--<webix-ui
                                    :config="ui"
                                    v-model="stockists"
                                ></webix-ui>
                                <div id="stockistPaging"></div>-->
                            </div>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</template>

<script>
export default {
    props: {
        items_count: {
            required: false,
            default: 0,
        },
    },
};
</script>
