<template>
    <div>
        <webix-ui :config="ui" v-model="company_users"></webix-ui>
        <div id="usersPaging"></div>
        <div>
            <UsersEditModal v-if="showModal_editUsersModal" />
        </div>
    </div>
</template>

<script>
//eslint-disable-next-line
import usersEditModal from "./modals/usersEditModal.vue";
import { adminMethods, adminComputed } from "@/state/helpers";
import Swal from "sweetalert2";
import UsersEditModal from "./modals/usersEditModal.vue";

export default {
    components: { usersEditModal, UsersEditModal },
    data: () => ({
        showModal_editUsersModal: false,
        ui: {
            id: "usersTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "usersPaging",
                size: 15,
                group: 5,
            },

            columns: [
                //need to create functionality for this
                {
                    id: "actions",
                    header: "Actions",
                    fillspace: 0.5,
                    template: function () {
                        return '<div class="tableIcons"><i class="mdi mdi-trash-can deleteAction"></i>&nbsp<i class="mdi mdi-account-edit editAction"></i></div>';
                    },
                },
                {
                    //these need to match the returned json object propertie names
                    id: "name",
                    header: ["Name", { content: "textFilter" }],
                    tooltip: false,
                    fillspace: 1,
                },
                {
                    id: "email",
                    header: ["Email", { content: "textFilter" }],
                    fillspace: 0.8,
                },
                {
                    id: "role_name",
                    header: ["Type of user", { content: "textFilter" }],
                    fillspace: 0.8,
                },
                {
                    id: "branch_name",
                    header: ["Branch", { content: "selectFilter" }],
                    fillspace: 0.8,
                },
            ],
            on: {},
            onClick: {
                //eslint-disable-next-line
                viewAction: function (id, e, trg) {
                    //var el = this.$scope.$parent;
                    //el.$emit('editSupplier', e.row);
                }, //eslint-disable-next-line
                deleteAction: function (id, e, trg) {
                    const el = this.$scope.$parent;
                    const item = this.getItem(e.row);

                    Swal.fire({
                        title: `Are you sure you want to delete 
                            "${item.name}" ?`,
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, delete!",
                    }).then((result) => {
                        if (result.value) {
                            el.ready = false;
                            el.deleteCompanyUser({ id: e.row }).then(() => {
                                // el.getStockists().then(() => {
                                //     el.ready = true;
                                // });
                                Swal.fire("Company user was deleted", "", "success");

                                //refresh the table on the screen
                                el.setCompanyUsers();
                            });
                        }
                    });
                },
                //eslint-disable-next-line
                editAction: function (id, e, trf) {
                    //eslint-disable-next-line
                    const el = this.$scope.$parent;
                    //get the table rows details
                    el.setSelectedUserToEdit(this.getItem(e.row));

                    el.editUser();
                },
            },
        },
    }),
    computed: {
        ...adminComputed,
        users() {
            return [];
        },
    },
    methods: {
        ...adminMethods,
        editUser() {
            this.showModal_editUsersModal = true;
            this.$nextTick(() => {
                this.$bvModal.show("userEditModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "userEditModal") {
                        this.showModal_editUsersModal = false;
                    }
                });
            });
        },
    },
    mount() {
        if (this.company_users == 0) {
            this.setCompanyUsers();
        }
    },
};
</script>

<style></style>
