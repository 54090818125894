<template>
    <div>
        <simpleLayout :items_count="this.company_users.length" :users="this.company_users">
            <template #buttons>
                <button type="button" class="btn btn-success mb-2 mr-2" @click="addUser"><i class="mdi mdi-plus mr-1"></i> Add User</button>
            </template>
            <template #table>
                <usersTable />
            </template>
        </simpleLayout>
        <usersModal v-if="show_modal" />
    </div>
</template>

<script>
import usersModal from "./modals/usersModal.vue";

import usersTable from "./usersTable.vue";
import simpleLayout from "@/components/layout-templates/simple-layout.vue";

import { adminMethods, adminComputed } from "@/state/helpers";

export default {
    components: { simpleLayout, usersModal, usersTable },
    data: () => ({
        show_modal: false,
    }),
    computed: {
        ...adminComputed,
    },
    methods: {
        ...adminMethods,
        addUser() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("usersModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "usersModal") {
                        this.show_modal = false;
                    }
                });
            });
        },
    },
    mounted() {
        if (this.company_users.length === 0) {
            this.setCompanyUsers();
        }
    },
};
</script>

<style></style>
