<template>
    <b-modal title="Add a user" id="usersModal" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group
                    id="example-input-group-1"
                    label="Name"
                    label-for="example-input-1"
                >
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 3
                        characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group
                    id="example-input-group-1"
                    label="Email"
                    label-for="example-input-1"
                >
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >Please enter a valid email</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group
                    id="example-input-group-2"
                    label="Select the users role"
                    label-for="example-input-2"
                >
                    <b-form-select
                        id="example-input-2"
                        name="example-input-2"
                        v-model="$v.form.role_id.$model"
                        :options="optionsList"
                        :state="validateState('role_id')"
                        aria-describedby="input-2-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="input-2-live-feedback"
                        >This is a required field.</b-form-invalid-feedback
                    >
                </b-form-group>

                <!-- <b-form-group
                    id="example-input-group-1"
                    label="User will be added to the following branch"
                    label-for="example-input-1"
                >

                    <b-form-input
                        class="input-readonly"
                        :value="branchName"
                        id="example-input-1"
                        name="example-input-1"
                        readonly
                    ></b-form-input>
                </b-form-group> -->

                <!-- footer -->
                <div class="modal-footer">
                    <b-button type="submit" variant="primary"
                        >Add user</b-button
                    >
                    <b-button class="ml-2" @click="resetForm()">Reset</b-button>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="closeModal()"
                    >
                        Close
                    </button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { adminMethods, adminComputed } from "@/state/helpers";
import Swal from "sweetalert2";

export default {
    mixins: [validationMixin],
    data() {
        return {
            current_branch: -1,
            formSubmit: false,
            busySaving: false,
            form: {
                name: null,
                role_id: null,
                email: null,
                branch_id: null,
            },
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            name: {
                required,
                minLength: minLength(3),
            },
            role_id: {
                required,
            },
        },
    },
    computed: {
        ...adminComputed,
        branchName() {
            if (this.selected_branch_id == 1) {
                return "Cape Town";
            } else {
                return "Johannesburg";
            }
        },
        optionsList() {
            const options = [];

            const defaultSelectedOption = {
                value: null,
                text: "Please select an option",
                disabled: true,
            };

            options.push(defaultSelectedOption);

            this.user_roles.forEach((element) => {
                if (element.id === 1 || element.id === 2) {
                    let newOption = {
                        value: element.id,
                        text: element.display_name,
                    };
                    options.push(newOption);
                }
            });

            return options;
        },
    },
    methods: {
        ...adminMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        resetForm() {
            this.form = {
                name: null,
                role_id: null,
                email: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        onSubmit() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;

            this.form.branch_id = this.selected_branch_id;

            this.createUser(this.form).then(() => {
                Swal.fire(
                    `${this.form.name} was added.`,
                    `User updated`,
                    "success"
                );
                this.setCompanyUsers();

                this.busySaving = false;
                this.closeModal();
            });
        },
        closeModal() {
            this.$bvModal.hide("usersModal");
        },
    },
    mounted() {
        if (this.user_roles == 0) {
            this.setUserRoles();
        }
    },
};
</script>
<style>
input[readonly] {
    background-color: red;
}
</style>
